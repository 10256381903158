/** @jsx jsx */
import React, { useState, useEffect } from "react";
import {
    Layout as AntLayout,
    Breadcrumb,
    Row,
    Anchor,
    Badge as AntBadge,
} from "antd";
import { jsx, Flex, Box, Heading, Paragraph, Badge, Avatar, Link as Link2 } from "theme-ui";
import "../pages/static/style.less";
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import queryString from "query-string";
import { CopyButton } from '@contentful/f36-components';
import Icon from "../components/shared/Icon";
import Layout from "../components/Layout";
import { DATASOURCE_DETAILS } from '../components/shared/DatasourceDetails';
import GithubIcon from "../img/avatars/github.png";
import SEO from "../components/seo";


const { Content } = AntLayout;
const { Link } = Anchor;

function BadgePicker(maturity) {
    if (maturity.toLowerCase() === "production") {
        return "success";
    }
    if (maturity.toLowerCase() === "beta") {
        return "warning";
    }
    if (maturity.toLowerCase() === "experimental") {
        return "error";
    }
    return "default";

}

function constructUrl(filterType, subFilterValues) {
    // encode the parameters to ensure they can be safely included in a URL
    const encodedFilterType = encodeURIComponent(filterType);
    const encodedSubFilterValues = encodeURIComponent(subFilterValues);

    // construct the URL
    const url = `filterType=${encodedFilterType}&viewType=Summary&showFilters=true&subFilterValues=${encodedSubFilterValues}`;

    return url;
}

const redirect = (filterType, subFilterValues, newTab = false) => {
    const route = "/expectations/"; // replace with your route
    const queryParams = constructUrl(filterType, subFilterValues);
    const url = `${window.location.origin}${route}?${queryParams}`;

    if (newTab) {
        window.open(url, '_blank');
    } else {
        window.location.href = url;
    }
}


function Profile({ content }) {
    return (
        <Box>
            <Paragraph variant="lessBold" mb="8px">
                Contributors:
            </Paragraph>
            <Box mb="24px">
                <Flex mb="8px" sx={{ flexWrap: "wrap" }}>
                    {content.metadata.contributors && content.metadata.contributors.length > 0 &&
                        content.metadata.contributors.map((contributor) => (
                            <a
                                key={contributor}
                                target="_blank"
                                href={`https://github.com/${contributor === "@great_expectations" ? "great-expectations" : contributor.substring(1)}`}
                                rel="noreferrer"
                            >
                                <Flex mr="24px" mb="8px" color="#262626" sx={{ alignItems: "center" }}>
                                    <Avatar
                                        src={`https://github.com/${contributor === "@great_expectations" ? "great-expectations" : contributor.substring(1)}.png`}
                                        onError={({ currentTarget }) => {
                                            const currentElement = currentTarget;
                                            currentElement.onerror = null;
                                            currentElement.src = GithubIcon;
                                        }}
                                        size="24"
                                        shape="circle"
                                        mr="8px"
                                    />
                                    {contributor}
                                </Flex>
                            </a>
                        )
                        )}
                </Flex>
            </Box>
            <Box>
                <Paragraph variant="lessBold" mb="8px">
                    Tags:
                </Paragraph>
                <Flex sx={{ marginBottom: "24px", flexWrap: "wrap" }}>
                    {content.metadata.tags.map((tag) => (
                        <Badge variant="gray" key={tag} mr="8px" mb="8px">
                            {tag}
                        </Badge>
                    )
                    )}
                </Flex>
            </Box>
            <Box>
                <Paragraph variant="lessBold" mb="8px">
                    Metrics:
                </Paragraph>
                <Flex sx={{ marginBottom: "24px", flexWrap: "wrap" }}>
                    {content.metrics.map((metric) => (
                        <Badge variant="gray" sx={{ cursor: "pointer" }} key={metric.name} mr="8px" mb="8px" 
                               onClick={() => redirect("Metrics", metric.name)}
                               onContextMenu={(event) => {
                                event.preventDefault();
                                redirect("Metrics", metric.name, true);
                            }}>
                          {metric.name}
                        </Badge>
                    )
                    )}
                </Flex>
            </Box>
        </Box>
    );
}

function ExpectationHeader({ content, mobile }) {
    return (
        <div sx={{ marginBottom: "24px" }}>
            {!mobile && (
                <Heading
                    as='h3'
                    sx={{
                        overflowWrap: "anywhere",
                        fontWeight: "bold"
                    }}
                >
                    {content.description.snake_name}
                </Heading>
            )}
            {mobile && (
                <Box justify="center" align="top">
                    <div>
                        <h4 sx={{ marginTop: "4px" }}>
                            {content.description.snake_name}
                        </h4>
                    </div>
                </Box>
            )}
            <Flex sx={{ marginBottom: "8px", alignItems: "baseline" }}>
                <AntBadge status={BadgePicker(content.metadata.maturity)} />
                <Paragraph variant="Paraghraph">
                    This expectation level is{" "}
                    {content.metadata.maturity || "experimental"}
                </Paragraph>
            </Flex>
            {mobile && (
                <Row sx={{ backgroundColor: "#fff" }}>
                    <Profile content={content} />
                </Row>
            )}
        </div>
    );
}

function ExecutionEngines({ content, ...props }) {
    const [supportedTech, setSupportedTech] = useState([]);
    const [notSupportedTech, setNotSupportedTech] = useState([]);
    const Engines = content.execution_engines;
    const Backend = content.backend;
    const EnginesKeys = Object.keys(content.execution_engines);
    const EngineArray = [];

    function NeedaContribution() {
        EnginesKeys.forEach((item) => {
            EngineArray.push(Engines[item]);
        });
    }
    NeedaContribution();

    const checkSupportedTech = (techObject) => {
        const supportedTechArray = [];
        const notSupportedTechArray = [];

        DATASOURCE_DETAILS.forEach((tech, i) => {
            const { name, displayName } = tech;
            if (techObject !== undefined && techObject[i]?.num_passed > 0 && techObject?.findIndex(ele => ele.backend === name) !== -1) {
                supportedTechArray.push(displayName);
            } else {
                notSupportedTechArray.push(displayName);
            }

            setSupportedTech(supportedTechArray);
            setNotSupportedTech(notSupportedTechArray);
        });
    };
    useEffect(() => {
        checkSupportedTech(Backend);
    }, []);

    return (
        <Box {...props}>
            <Box mb="8px">
                <Paragraph variant="lessBold" id="execution_engines">
                    Backend support:
                    <a href="#execution_engines" className="anchor">
                        #
                    </a>
                </Paragraph>
            </Box>
            <Box>
                <Flex mb="8px" sx={{ flexWrap: "wrap" }}>
                    {supportedTech.map((tech) => (
                        <Badge
                            key={tech}
                            variant="green"
                            sx={{ display: "flex", alignItems: "center", margin: "4px", cursor: "pointer" }}
                            onContextMenu={(event) => {
                                event.preventDefault();
                                redirect("Backend support", tech, true);
                            }}
                            onClick={() => redirect("Backend support", tech)}
                        >
                            <Icon icon="check-circle" sx={{ mr: "5px" }} />
                            {tech}
                        </Badge>
                    ))}
                </Flex>
                <Flex sx={{ flexWrap: "wrap" }}>

                    {notSupportedTech.length !== 0 && (
                        <div>
                            <Flex sx={{ alignItems: "center" }}>
                                <AntBadge status="default" />
                                <Paragraph
                                    sx={{ fontSize: ["12px", "14px"], color: "#262626" }}
                                >
                                    Contribution Needed
                                </Paragraph>
                                <a
                                    target="_blank"
                                    href="https://docs.greatexpectations.io/docs/guides/expectations/contributing/how_to_contribute_a_custom_expectation_to_great_expectations/"
                                    rel="noreferrer"
                                >
                                    <Icon
                                        icon="external-link"
                                        fontSize="18px"
                                        sx={{ color: "#ff6310", ml: "8px", mb: "3px" }}
                                    />
                                </a>
                            </Flex>
                            <Flex mt={2} sx={{ flexWrap: "wrap" }}>
                                {notSupportedTech.map((tech) => (
                                    <Badge
                                        key={tech}
                                        variant="gray"
                                        sx={{ display: "flex", alignItems: "center", margin: "4px", cursor: "pointer" }}
                                        onContextMenu={(event) => {
                                            event.preventDefault();
                                            redirect("Backend support", tech, true);
                                        }}
                                        onClick={() => redirect("Backend support", tech)}>
                                        <Icon icon="error" sx={{ mr: "5px" }} />
                                        {tech}
                                    </Badge>
                                ))}
                            </Flex>
                        </div>
                    )}
                </Flex>
            </Box>
            <Box mb="8px" mt="20px">
                <Flex sx={{ flexWrap: "wrap" }}>
                    {content.exp_type && (<Flex sx={{ alignItems: "baseline", mb: "16px" }}>
                        <Paragraph
                            variant="lessBold"
                            sx={{
                                minWidth: "20%"
                            }}
                        >
                            Expectation Type:
                        </Paragraph>
                        <Badge variant="gray" sx={{ cursor: "pointer", ml: "16px" }}  
                               onContextMenu={(event) => {
                                event.preventDefault();
                                redirect("Expectation Class", content.exp_type, true);
                                }}
                               onClick={() => redirect("Expectation Class", content.exp_type)}>
                            <Paragraph >
                                {content.exp_type ? content.exp_type : ''}
                            </Paragraph>
                        </Badge>

                        {!content.exp_type && (
                          <Flex sx={{ color: '#595959', marginLeft: '16px' }}>
                            <AntBadge status="default" sx={{ marginRight: '8px' }} />
                            <a
                              sx={{ color: '#595959' }}
                              target="_blank"
                              href="https://docs.greatexpectations.io/docs/guides/expectations/creating_custom_expectations/overview/"
                              rel="noreferrer"
                            >
                              <Flex sx={{ alignItems: 'center' }}>
                                <Paragraph variant="paragraphSmall">Contribution Needed</Paragraph>
                                <Icon icon="external-link" fontSize="14px" sx={{ color: '#ff6310', ml: '8px' }} />
                              </Flex>
                            </a>
                          </Flex>
                        )}
                    </Flex>
                    )}
                </Flex>
            </Box>
        </Box>
    );
}


function code({ node, inline, className, children, ...props }) {
    return (
        <code className={className} {...props}>
            {(!inline || node.children[0].value.length > 200) && <CopyButton
                tooltipProps={{ placement: 'bottom', usePortal: true }}
                value={node.children[0].value}
                className="code-copy-button"
                size="small"
            />}
            {children}
        </code>
    );
}

function ExpectationDescription({ content }) {
    return (
        <div>
            <Row className="expectation-description">
                <Heading as="h4" id="description">
                    Description
                </Heading>
            </Row>

            <Row>
                <span>
                    <Flex sx={{ alignItems: "baseline", mb: "16px" }}>
                        {content.description.docstring === null ||
                            (content.description.docstring === "" && (
                                <Flex sx={{ color: "#595959", marginLeft: "16px" }}>
                                    <AntBadge status="default" sx={{ marginRight: "8px" }} />
                                    <a
                                        sx={{ color: "#595959" }}
                                        target="_blank"
                                        href="https://docs.greatexpectations.io/docs/guides/expectations/creating_custom_expectations/overview/"
                                        rel="noreferrer"
                                    >
                                        <Flex sx={{ alignItems: "center" }}>
                                            <Paragraph variant="paragraphSmall">
                                                Contribution Needed
                                            </Paragraph>
                                            <Icon
                                                icon="external-link"
                                                fontSize="14px"
                                                sx={{ color: "#ff6310", ml: "8px" }}
                                            />
                                        </Flex>
                                    </a>
                                </Flex>
                            ))}
                    </Flex>
                    {content.description.docstring !== null &&
                        content.description.docstring !== "" && (
                            <span
                                sx={{
                                    fontSize: "12px",
                                    lineHeight: "10px",
                                    marginTop: "8px",
                                    overflowX: "scroll",
                                }}
                            >
                                <ReactMarkdown
                                    className="markdown"
                                    remarkPlugins={[remarkGfm, remarkBreaks]}
                                    components={{ code }}>
                                    {content.description.docstring}
                                </ReactMarkdown>
                            </span>
                        )}
                </span>
            </Row>
        </div>
    );
}

function ContributionsNeeded() {
    return (
        <Row>
            <Heading as="h4">
                Want to make your own Expectation or an improvement to this one?
            </Heading>
            <p sx={{ marginBottom: "24px" }}>
                We&apos;ve put together some great how to guides (including videos) on how to
                create your own expectations in a flash! <br /> You can see those
                resources here:{" "}
                <a
                    target="_blank"
                    href="https://docs.greatexpectations.io/docs/guides/expectations/creating_custom_expectations/overview/"
                    rel="noreferrer"
                >
                    Contributor Resources
                </a>
            </p>
        </Row>
    );
}

function ExpectationBody({ content }) {
    return (
        <div className="expectation-body">
            <ExpectationDescription content={content} />
            <ContributionsNeeded content={content} />
        </div>
    );
}

function SideMenu() {
    return (
        <Anchor offsetTop={96}>
            <Link
                href="#execution_engines"
                key="Execution Engines"
                title="Backend Support"
            />
            <Link
                href="#description"
                key="Description"
                title="Description"
            />
            <Link
                href="#renderers"
                key="Renderers"
                title="Renderers"
            />
            <Link
                href="#examples"
                key="Examples"
                title="Examples"
            />
        </Anchor>
    );
}



//
// BEHOLD THE EXPECTATION ITSELF:
//
function Expectation({ pageContext }) {

    let queryParams = '';
    if (typeof window !== "undefined")
        queryParams = queryString.parse(window.location.search);
    const [filterUrl, setFilterUrl] = useState('');

    useEffect(() => {
        if (queryParams && queryParams.subFilterValues) {
            setFilterUrl(`?filterType=${queryParams.filterType}&gotoPage=${queryParams.gotoPage}&showFilters=${queryParams.showFilters}&viewType=${queryParams.viewType}&subFilterValues=${queryParams.subFilterValues}`);
        } else if (queryParams) {
            setFilterUrl(`?filterType=${queryParams.filterType}&gotoPage=${queryParams.gotoPage}&showFilters=${queryParams.showFilters}&viewType=${queryParams.viewType}`);
        } else {
            setFilterUrl('');
        }
    }, [queryParams]);

    return (
        <Layout>
            <SEO
                title={pageContext?.description?.snake_name}
                description={pageContext?.description?.short_description}
            />
            <div className="gallery-container">
                <Breadcrumb
                    sx={{
                        maxWidth: "1200px",
                        margin: "24px auto 40px auto",
                        overflowWrap: "anywhere",
                        fontSize: "18px",
                    }}
                >
                    <Breadcrumb.Item href={`/expectations${filterUrl}`}>
                        Expectation Gallery
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        {pageContext.description.snake_name}
                    </Breadcrumb.Item>
                </Breadcrumb>

                <Flex>
                    <Flex
                        sx={{
                            backgroundColor: "#fff",
                            flexDirection: 'column',
                            pr: 2,
                            flex: '1 0 25%',
                            display: ['none', 'none', 'none', 'none', 'flex']
                        }}>
                        {pageContext.description.short_description && (
                            <Box
                                sx={{
                                    backgroundColor: "#FAFAFA",
                                    border: '1px solid #F0F0F0',
                                    borderRadius: '4px',
                                    padding: 3,
                                    marginBottom: 4,
                                }}
                            >
                                <Paragraph sx={{ fontSize: ["12px", "14px"] }}>
                                    {pageContext.description.short_description}
                                </Paragraph>
                            </Box>
                        )}
                        <SideMenu content={pageContext} />
                    </Flex>
                    <Flex
                        sx={{
                            flex: '1 0 75%',
                            pl: [0, 0, 0, 0, 4],
                        }}
                    >
                        <Content sx={{ width: '100%' }}>
                            <ExpectationHeader content={pageContext} mobile={false} />
                            <Profile content={pageContext} />
                            <ExecutionEngines
                                mobile={false}
                                content={pageContext}
                                sx={{ mt: "40px" }}
                            />
                            <ExpectationBody content={pageContext} mobile={false} />
                        </Content>
                    </Flex>
                </Flex>
            </div>
        </Layout>
    );
}
export default Expectation;
